.vco-media {
	.vco-media-image {
		width: auto !important;
	}

}

.vco-mobile.vco-skinny {
	.vco-media {
		.vco-media-image {
			max-height:250px !important;
		}

	}
}