/* Requires Variables.less
================================================== */

.vco-text {
	width:50%;
	max-width:50%;
	min-width:120px;
	padding: 0 20px 0 20px;
	display:table-cell;
	display:block;
	vertical-align:middle;
	text-align: left;
	//float:left;
	text-shadow: none;
	color:@color-text;
	p {
		color:@color-text;
	}
	.vco-text-content-container {
		.vco-text-content{
		
		}
	}
	h2.vco-headline-title, h2.vco-headline {
		margin-top:0;
		color:@color-header-text;
	}
	.vco-headline-date, h3.vco-headline-date {
		font-family:@font-main;
		font-size:@base-font-size;
		line-height: @base-font-size;
		font-weight: normal;
		margin:0 0 2px 0;
		color: lighten(@color-text,25);
		small {
			font-size:@base-font-size;
			line-height: @base-font-size;
			font-weight: normal;
			color: lighten(@color-text,25);
		}
	}
	.vco-text-date {
		display:inline-block;
		font-family:@font-main;
		font-weight:normal;
		margin-top:10px;
		font-size:12px;
		color: lighten(@color-text,25);
		&:after {
			//margin-left:3px;
			//color:#999;
			//font-weight:normal;
		}
	}

}

// When the background is an image
.vco-full-image-background, .vco-full-color-background {
	.vco-text, .vco-text p {
		color: @color-text-inverted !important;
		text-shadow: 1px 1px 2px #000;
		.vco-headline-date, h3.vco-headline-date {
			color: @color-text-inverted !important;
			small {
				color: @color-text-inverted !important;
			}
		}

	}
}


/* Skinny
================================================== */
.vco-skinny {
	.vco-text {
		width:100%;
		max-width:100%;
		min-width:auto;
		float:none;
		margin-top:20px;
		.vco-text-content-container {
			.vco-text-content{
		
			}
		}
	}
}



/* Mobile, iPhone
================================================== */
.vco-mobile {
	
}

/* Mobile, iPhone and skinny
================================================== */
.vco-mobile.vco-skinny {

}
