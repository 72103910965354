/* StorySlider
================================================== */

/* SLIDER CONTAINERS 
================================================== */
.vco-storyslider {
	width:100%;
	height:100%;
	overflow:hidden;
	.user-select(none);
	position:relative;
	img, embed, object, video, iframe {
		max-width: 100%;
		position:relative;
	}
	.vco-slider-background {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:1;
		pointer-events: none;
	}
	.vco-slider-touch-mask {
		width:100%;
		height:100%;
		z-index:25;
		
		top:0px;
		left:0px;
		position: absolute;
	}
	.vco-slider-container-mask {
		text-align: center;
		width: 100%;
		height: 100%;
		//overflow: scroll;
		position: relative;
		z-index:5;
		.vco-slider-container {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			text-align: center;
			
			.vco-slider-item-container {
				width: 100%;
				height: 100%;
				display:table-cell;
				vertical-align:middle;

			}
		}
	}
	
}
