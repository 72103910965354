/*!
	StoryMap

	Designed and built by Zach Wise for the Northwestern University Knight Lab

	This Source Code Form is subject to the terms of the Mozilla Public
	License, v. 2.0. If a copy of the MPL was not distributed with this
	file, You can obtain one at http://mozilla.org/MPL/2.0/.

*/

/* Includes
================================================== */
@import "Variables.less"; 			// Variables (easy way to make style changes)

@import "core/Mixins.less"; 		// Less shortcuts and functions
@import "core/Reset.less"; 			// CSS Reset
@import "Typography.less";

// Icons
@import "icons/Icons.less";

//	Components
@import "core/VCO.less";

//	Components
@import "ui/VCO.MenuBar.less";
@import "ui/VCO.MenuBar.Button.less";
@import "ui/VCO.Message.less";

@import "animation/VCO.Animate.less";

@import "slider/VCO.Slide.less";
@import "slider/VCO.SlideNav.less";
@import "slider/VCO.StorySlider.less";

@import "media/VCO.Media.less";
@import "media/types/VCO.Media.Text.less";
@import "media/types/VCO.Media.Image.less";
@import "media/types/VCO.Media.Twitter.less";
@import "media/types/VCO.Media.Blockquote.less";
@import "media/types/VCO.Media.Flickr.less";
@import "media/types/VCO.Media.Instagram.less";
@import "media/types/VCO.Media.Profile.less";
@import "media/types/VCO.Media.YouTube.less";
@import "media/types/VCO.Media.IFrame.less";
@import "media/types/VCO.Media.Wikipedia.less";

@import "map/leaflet/VCO.Leaflet.less";
@import "map/leaflet/VCO.Leaflet.IE.less";
@import "map/leaflet/VCO.Map.Leaflet.less";
@import "map/leaflet/VCO.Leaflet.MiniMap.less";
@import "map/leaflet/VCO.Leaflet.Mapbox.less";

@import "map/VCO.MapMarker.less";
@import "map/VCO.ImageMarker.less";
@import "map/VCO.Map.less";

@import "snapmap/VCO.SnapMap.less";

/* StoryMap
================================================== */
.vco-storymap {
	width:100%;
	height:100%;
	font-size: 16px;
	line-height: normal;
	overflow:hidden;
	position: relative;
	.user-select(none);
    *, *::before, *::after {
        box-sizing: content-box;
    }
}

/* Portrait
================================================== */
.vco-layout-portrait {
	.vco-storyslider {
		padding-top:10px;
		.box-shadow(0px -3px 6px rgba(0,0,0,.20));
	}
}
/* Landscape
================================================== */
.vco-layout-landscape {
	.vco-storyslider {
		padding-top:0px;
		//this._el.slider_container_mask.style.width = this.options.width  + "px";
		//this._el.slider_container_mask.style.left =  this.options.width  + "px";
		position:absolute;
		width: 50%;
		left: 50%;
		overflow:visible;
		//padding-left:10%;
		.vco-slider-background {
			margin-left:-20%;
			width:120%;
			overflow:hidden;
			#gradient > .horizontal(@start-color: rgba(256,256,256,.0001); @end-color: rgba(256,256,256,0.85); @start-percent: 0%; @end-percent: 15%);
			//#gradient > .horizontal-three-colors(@start-color: rgba(256,256,256,.0001); @mid-color: rgba(256,256,256,0.85); @color-stop: 10%; @end-color: rgba(256,256,256,.50));
		}
		.vco-slider-container-mask {
			overflow: visible;

			//.translucent-background(#FFF, 0.70);
			//#gradient > .horizontal(@start-color: rgba(256,256,256,.0001); @end-color: rgba(256,256,256,0.85); @start-percent: 0%; @end-percent: 25%);
			//#gradient > .horizontal-three-colors(@start-color: rgba(256,256,256,.0001); @mid-color: rgba(256,256,256,0.85); @color-stop: 10%; @end-color: rgba(256,256,256,.50));
			//#gradient > .radial(@inner-color: rgba(256,256,256,0.85); @outer-color: rgba(256,256,256,.0001));
		}
	}
}
/* Default Fonts
================================================== */
/*
.vco-storymap {
	font-family:'Pontano Sans',sans-serif !important;
}
.vco-storymap {
	h1, h2, h3, h4, h5, h6 {
		font-family:'Bevan',serif !important;
		font-weight: normal;
	}
}
.vco-secondary-font {

}
*/


/* Right to Left
================================================== */
.vco-rtl {
	.vco-text-content, .vco-text, .vco-caption, .vco-media-wikipedia, .vco-headline, .vco-media-blockquote{
		text-align: right;
	}
}
