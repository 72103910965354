.vco-media {
	.vco-media-blockquote {
		text-align:left;
		clear:both;
		blockquote {
			margin:0;
			margin-right: 	@base-spacing;
			text-align: 	left;
			p {
				font-size: 		@base-font-size-xlarge;
				line-height: 	floor(@base-font-size-xlarge * 1.1);
				//color:			@color-dark;
			}
			cite {
				font-size: 		@base-font-size;
				line-height:	floor(@base-font-size * @base-line-height);
				color:			@color-foreground;
				text-align: 	right;
			}
			
		}

		blockquote p:before {
			content: open-quote;
			display:inline-block;
			//font-size: (@base-font-size-xlarge * 3);
			//font-style: normal;
			//position: relative;  
			//top: (@base-font-size-xlarge);
			margin-right:5px;
		
		}
		blockquote p:after {
			content: close-quote;
			display:inline-block;
			//font-size: (@base-font-size-xlarge * 2);
			//position: relative;  
			//top: (@base-font-size-xlarge/2);
			margin-left:3px;
		
		}

	}

}
.vco-slide-media-only {
	.vco-media {
		.vco-media-blockquote {
			width:80%;
			margin-left:auto;
			margin-right:auto;
		}
	}
}
// Skinnier
@media only screen and (max-width: 800px), only screen and (max-device-width: 800px) {
	.vco-media {
		.vco-media-blockquote {
			blockquote {
				p {
					font-size: 		@base-font-size-large;
					line-height:	floor(@base-font-size-large * 1.1);
				}
			}
		}

	}
}
// Mobile, iPhone and skinny
@media only screen and (max-width: 500px), only screen and (max-device-width: 500px) {
	.vco-media {
		.vco-media-blockquote {
			blockquote {
				p {
					font-size: 		@base-font-size;
					line-height:	floor(@base-font-size * @base-line-height);
				}
			}
		}

	}
}