.vco-media {
	.vco-media-profile {
		border-radius: 50%;
	}

}

// Mobile, iPhone and skinny
@media only screen and (max-width: 500px), only screen and (max-device-width: 500px) {
	.vco-media {
		.vco-media-profile {
		
		}

	}
}