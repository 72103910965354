/* Requires Variables.less
================================================== */
.vco-map {
	height: 100%;
	overflow: hidden;
	top:0px;
	background-color: @color-foreground;
	.vco-map-mask {
		width: 100%;
		height:	100%;
		overflow: hidden;
		.vco-map-display {
			width: 100%;
			height:	100%;
		}
		
	}
	
}



/* Mobile, iPhone and skinny
================================================== */
@media only screen and (max-width: 500px), only screen and (max-device-width: 500px) {
	.vco-map {

	}
}

