.vco-media {
	.vco-media-instagram {
		
	}

}


.vco-mobile.vco-skinny {
	.vco-media {
		.vco-media-instagram {
			max-height:250px !important;
		}

	}
}