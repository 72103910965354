.vco-media {
	.vco-media-wikipedia {
		//font-size: @base-font;
		//line-height: @base-line;
		text-align:left;
		margin-left: auto;
		margin-right: auto;
		//margin-bottom:@base-space;
		clear:both;
		.wiki-source { 
			//margin-bottom:@base-space;
			font-size: 13px;
			line-height: 19px;
			font-style: italic;
		}
		h4 {
			margin-top:0px;
			//border-bottom: 1px solid @color-line;
			margin-bottom:0px;
		}
		h4 a {
			color:@color-dark; //@color-theme;
			text-decoration: none;
		}
		h4 a:hover {
			text-decoration: underline;
		}
		
		p {
			font-size: 13px;
			line-height: 19px;
		}
		
	}

}
.vco-slide.vco-full-image-background, .vco-slide.vco-full-color-background {
	.vco-media {
		.vco-media-wikipedia {
			padding:20px;
			.background-color-opacity(0,0,0, 60);
			.border-radius(7px);
			h4 a {
				.slide-text-shadow();
			}
		}
	}
}
// Mobile, iPhone and skinny
.vco-mobile.vco-skinny {
	.vco-media {
		.vco-media-wikipedia {
			margin-left: 10px;
			margin-right: 10px;
		}
		
	}
}