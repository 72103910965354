/* MenuBar 
================================================== */

.vco-menubar {
	position:absolute;
	//width:100%;
	//height:	1px;
	z-index:11;
	text-align:left;
	//color:#333;
	//height:26px;
	//background-color:#FFF;
	//.box-shadow(0px -3px 6px rgba(0,0,0,.20));
	overflow:hidden;
	//margin-top:-25px;
	border-bottom: 1px solid darken(@color-background,10);
	border-right: 1px solid darken(@color-background,10);
	border-bottom-right-radius: 7px;
	left:0px;
	//.border-radius(7px);
	//border-bottom-left-radius: 7px;
	.vco-menubar-button:first-child {
		border-left:0;
	}
}
.vco-mobile {

}
/* Landscape
================================================== */
.vco-layout-landscape {
	.vco-menubar {
		//border-bottom: 1px solid darken(@color-background,10);
		
		//.box-shadow(1px 1px 3px rgba(0,0,0,.30));
	}
}

/* Color
================================================== */

// Inverted
/*
.vco-sizebar.vco-sizebar-inverted {
	border-bottom: 1px solid #FFF;
	//background-color:#000;
	color:#a5a5a5;
	.vco-sizebar-button {
		border-left: 1px solid darken(@color-background, 70);
		//color:#a5a5a5;
	}
	.vco-sizebar-button:hover {
		//background:@color-theme;
		color:@color-background;
	}
}
.vco-sizebar.vco-sizebar-inverted:before {
	background-color:#000;
	//.gradient-vertical (rgba(0,0,0,0.25), rgba(0,0,0,1));
	//.translucent-background(rgb(0,0,0), .5);
	border-top: 2px solid #000;
	animation: invertToBlack 1s;
	-webkit-animation:invertToBlack 1s; 
}
*/

@keyframes invertToBlack {
	from {
		background-color:#FFF;
	}
	to {
		background-color:#000;
	}
}
@-webkit-keyframes invertToBlack {
	from {background:#FFF;}
	to {background:#000;}
}
@keyframes invertToWhite {
	from {background-color:#000;}
	to {background-color:#FFF;}
}
@-webkit-keyframes invertToWhite{
	from {background:#000;}
	to {background:#FFF;}
}


