.vco-storymap {

	
	h1, h2, h3 {
		.vco-snapmap-instagram {
		
		}

	}
	h4, h5, h6 {
		.vco-snapmap-instagram {
		
		}

	}
	h2.vco-headline-title {
		.vco-snapmap-instagram, .vco-snapmap-flickr {
			text-align:center;
			display:block;
			text-transform: none !important;
			a {
				color:lighten(@color-dark, 40%);
			}
		}

	}
	.vco-media-instagram-likes {
		display:inline-block;
		font-family:@font-main;
		font-weight:bold;
		margin-top:10px;
		font-size:12px;
		color: lighten(@color-dark, 40%);
		/*
		&:before {
			content:'\2665';
			margin-right:3px;
			font-weight:normal;
			font-size:14px;
		}
		*/
		&:after {
			content:'Likes';
			margin-left:3px;
			color:#999;
			font-weight:normal;
			margin-right:10px;
		}
	}
	.vco-snapmap-user-description, .vco-snapmap-user-location {
		font-family:@font-main;
		//font-size:12px;
		//width:50%;
		display:block;
		
	}
	
	.vco-snapmap-instagram-text {
		text-align:center;
		display:none;
		a {
			color:#000;
		}
		.vco-snapmap-instagram-posts,
		.vco-snapmap-instagram-followed_by,
		.vco-snapmap-instagram-follows,
		.vco-snapmap-instagram-website,
		.vco-snapmap-instagram-website a {
			display:inline-block;
			font-family:@font-main;
			font-weight:bold;
			margin-top:10px;
			font-size:12px;
			margin-left:20px;
			&:after {
				margin-left:3px;
				color:#999;
				font-weight:normal;
			}
		}
		.vco-snapmap-instagram-posts:after {
			content:'Posts';
		}
		
		.vco-snapmap-instagram-followed_by:after {
			content:'Followers';
		}
		
		.vco-snapmap-instagram-follows:after {
			content:'Following';
		}
		
		.vco-snapmap-instagram-website,
		.vco-snapmap-instagram-website a {
			font-weight:normal;
			display:block;
			margin:0;
			color:#999;
			&:after {
				margin-left:0;
				color:#999;
				font-weight:normal;
			}
		}
		
		.vco-snapmap-instagram-bio {
		}
	}
	
	.vco-snapmap-user-location {
		font-weight:bold;
		font-size:12px;
		text-align:center;
	}
	.vco-snapmap-flickr-views {
		display:block;
		font-family:@font-main;
		font-weight:bold;
		margin-top:10px;
		font-size:12px;
		&:before {
			
		}
		&:after {
			content:'Views';
			margin-left:3px;
			color:#999;
			font-weight:normal;
		}
	}
}
