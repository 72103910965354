.vco-media {
	.vco-media-twitter {
		text-align:left;
		//margin-left: auto;
		//margin-right: auto;
		//margin-bottom:@base-space;
		clear:both;
		blockquote {
			margin:0;
			margin-right: 	@base-spacing;
			font-size: 		@base-font-size;
			line-height:	floor(@base-font-size * @base-line-height);
			color:			@color-foreground;
			p {
				font-size: 		@base-font-size-xlarge;
				line-height: 	floor(@base-font-size-xlarge * 1.1);
				color:			@color-dark;
			}
			.quote-mark {
				
			}
		}
		blockquote p:before {
			display:none;
		}
		blockquote p:after {
			display:none;
		}
		
		.vco-icon-twitter {
			color:#55ACEE;
		}
		.vcard {
			a:hover, a.vco-date:hover {
				text-decoration: none;
				color:#55ACEE;
				.fn, .nickname {
					color:#55ACEE;
				}
			}
		}
		
		

	}

}
.vco-slide-media-only {
	.vco-media {
		.vco-media-twitter {
			width:80%;
			margin-left:auto;
			margin-right:auto;
		}
	}
}
.vco-mobile.vco-skinny {
	.vco-media {
		.vco-media-twitter {
			
			blockquote {
				p {
					font-size: 		@base-font-size;
					line-height:	floor(@base-font-size * @base-line-height);
				}
			}
		}

	}
}
.vco-skinny {
	
	.vco-media {
		.vco-media-twitter {
			margin-left:10px;
			margin-right:10px;
			blockquote {
				p {
					font-size: 		@base-font-size-large;
					line-height:	floor(@base-font-size-large * 1.1);
				}
			}
		}

	}
}
